import classes from "./App.module.css";
import Header from "./components/Header";
import { Navigate, Route, Routes } from "react-router-dom";
import About from "./Pages/About";
import Blogs from "./Pages/Blogs";
import Contact from "./Pages/Contact";
import Home from "./Pages/Home";
import Services from "./Pages/Services";
import Footer from "./components/Footer";
import NotFound from "./Pages/NotFound";

import { animateScroll as scroll } from "react-scroll";
import { useEffect, useState } from "react";

function App() {
  const [isScrollShown, setIsScrollShown] = useState(false);

  const scrollToTop = () => {
    scroll.scrollToTop();
  };
  const listenToScroll = () => {
    let heightToHidden = 300;
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;
    if (winScroll > heightToHidden) {
      setIsScrollShown(true);
    } else {
      setIsScrollShown(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", listenToScroll);
  }, []);

  return (
    <div className="App">
      <Header />

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/services" element={<Services />} />
        <Route path="/blogs" element={<Blogs />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/notfound" element={<NotFound />} />
        <Route path="*" element={<Navigate replace to="/notfound" />} />
      </Routes>
      {isScrollShown && (
        <button onClick={scrollToTop} className={classes.scroll_btn}>
          <ion-icon name="arrow-up"></ion-icon>
        </button>
      )}
      <Footer />
    </div>
  );
}

export default App;
