import React from "react";
import classes from "./Blogs.module.css";

const Blogs = () => {
  return (
    <div className={classes.blogs_container}>
      <div className={classes.container}>
        <div className={classes.title}>
          <h1 className={classes.title_primary}>Blog1</h1>
        </div>
        <div className={classes.image}></div>
        <div className={classes.description}>
          <p className={classes.description_text}>
            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Expedita
            non iste similique dicta consectetur itaque architecto voluptatibus.
            Laboriosam debitis repellendus quas, eos voluptate delectus eum esse
            sit soluta exercitationem asperiores, beatae minus quam? Quo
            veritatis doloribus quisquam unde dolor. Vel, perferendis sunt
            recusandae obcaecati iste sapiente. Distinctio incidunt ut inventore
            exercitationem et dolore? Beatae voluptate assumenda illum
            cupiditate alias similique officia fugiat in ipsum possimus aut illo
            accusantium, est necessitatibus aperiam, architecto molestias optio
            impedit dolorem nihil! Error dolore sit quia rerum ratione quod aut
            alias ut repudiandae totam possimus laudantium ipsa, corrupti eos.
            Ipsa deserunt magni explicabo nostrum voluptas?
          </p>
        </div>
        <div className={classes.btn_secondary}>
          <button>Read more</button>
        </div>
      </div>
    </div>
  );
};

export default Blogs;
