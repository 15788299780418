import React from "react";
import hero from "../assets/hero.png";
import blob3 from "../assets/blob3.png";

import classes from "./Home.module.css";
import About from "./About";
import Typewriter from "typewriter-effect";
import Services from "./Services";
import Contact from "./Contact";

import { Parallax } from "react-scroll-parallax";
import { Link } from "react-router-dom";

const Home = () => {
  return (
    <>
      <div className={classes.container}>
        <div className={classes.scatter__blob_box}>
          <img className={classes.scatter__blob} src={blob3} alt="" />
        </div>
        <div className={classes.main_content}>
          <Parallax speed={-2}>
            <h3>Hello!</h3>
          </Parallax>
          <Parallax speed={-2}>
            <h1>
              I'm <span className={classes.rubber}>M</span>
              <span className={classes.rubber}>i</span>
              <span className={classes.rubber}>n</span>
              <span className={classes.rubber}>h</span>
              <span className={classes.rubber}>a</span>
              <span className={classes.rubber}>z</span>
              <span className={classes.rubber}>u</span>
              <span className={classes.rubber}>r</span>{" "}
            </h1>
          </Parallax>
          <h2 className={classes.dynamic__text_field}>
            I'm a
            <span className={classes.dynamic__text}>
              <Typewriter
                options={{
                  strings: [
                    " Web Developer",
                    "Grphic Designer",
                    "UI/UX Designer",
                  ],
                  autoStart: true,
                  loop: true,
                }}
              />
            </span>
          </h2>
          <div className={classes.text_wrapper}>
            <h6>
              I am an independent frontend web developer and graphic designer. I
              like to design great UI and craft interactive scalable websites.
            </h6>
          </div>
          <Parallax speed={-15} className={classes.hire_btn}>
            <Link to="/contact">Hire Me</Link>
          </Parallax>
        </div>
        <div className={classes.blob_box}>
          <svg
            className={classes.blob1}
            xmlns="http://www.w3.org/2000/svg"
            width="456.083"
            height="770.216"
            viewBox="89.744 145.865 756.083 770.216"
          >
            <defs>
              <linearGradient id="b" gradientTransform="rotate(150 .5 .5)">
                <stop offset="0%" stopColor="#e98c23" />
                <stop offset="100%" stopColor="#feef7b" />
              </linearGradient>
              <clipPath id="a">
                <path fill="currentColor">
                  <animate
                    attributeName="d"
                    dur="10s"
                    repeatCount="indefinite"
                    values="M832 688.5q-31 188.5-232 221T256 814Q113 686 92 490t153.5-267.5Q420 151 603 146t221.5 174.5Q863 500 832 688.5Z;
                  M832.5 685.5Q795 871 608.5 856t-361-82.5Q73 706 147.5 536T326 279q104-87 238.5-58t220 154q85.5 125 48 310.5Z;
                  
                  M832 688.5q-31 188.5-232 221T256 814Q113 686 92 490t153.5-267.5Q420 151 603 146t221.5 174.5Q863 500 832 688.5Z"
                  ></animate>
                </path>
              </clipPath>
            </defs>
            <g clipPath="url(#a)">
              <path fill="url(#b)">
                <animate
                  attributeName="d"
                  dur="10s"
                  repeatCount="indefinite"
                  values="M832 688.5q-31 188.5-232 221T256 814Q113 686 92 490t153.5-267.5Q420 151 603 146t221.5 174.5Q863 500 832 688.5Z;
                M832.5 685.5Q795 871 608.5 856t-361-82.5Q73 706 147.5 536T326 279q104-87 238.5-58t220 154q85.5 125 48 310.5Z;
                
                M832 688.5q-31 188.5-232 221T256 814Q113 686 92 490t153.5-267.5Q420 151 603 146t221.5 174.5Q863 500 832 688.5Z"
                ></animate>
              </path>
            </g>
          </svg>
          <div className={classes.blob2}></div>
        </div>
        <div className={classes.hero}>
          <img className={classes.hero_img} src={hero} alt="hero" />
        </div>
      </div>

      <About />
      <Services />
      <Contact />
    </>
  );
};

export default Home;
