import React from "react";
import classes from "./Services.module.css";

import { Parallax } from "react-scroll-parallax";

import webdev from "../assets/illustrations/webdev.png";
import appdev from "../assets/illustrations/appdev.png";
import uiux from "../assets/illustrations/uiux.png";
import graphic from "../assets/illustrations/graphic.png";
import { Link } from "react-router-dom";

const Services = () => {
  return (
    <div className={classes.servies_container}>
      <div className={classes.primary_heading}>
        <Parallax translateX={["-200px", "50px"]}>
          <h1>
            <span className={classes.rubber}>M</span>
            <span className={classes.rubber}>y</span>
            <span className={classes.rubber_space}> </span>
            <span className={classes.rubber}>S</span>
            <span className={classes.rubber}>e</span>
            <span className={classes.rubber}>r</span>
            <span className={classes.rubber}>v</span>
            <span className={classes.rubber}>i</span>
            <span className={classes.rubber}>c</span>
            <span className={classes.rubber}>e</span>
            <span className={classes.rubber}>s</span>
          </h1>
        </Parallax>
        <div className={classes.underline}></div>
      </div>
      <div className={classes.flex_conatainer}>
        <div className={`${classes.flex_item} ${classes.flex_item_row}`}>
          <div
            className={`${classes.flex_description} ${classes.flex_item_column}`}
          >
            <h2>Web development</h2>
            <p>
              A great percentage of the success of your company depends on
              weather you have a great website or not. well I can do that for
              you. I use latest technologies like React js, redux toolkit,
              Restful APIs, firebase, mongodb to build your interactive, highly
              scalable website. I am also profound in cool animations and
              responsive design which will support in every device.
            </p>
            <Link to="/contact">Hire me</Link>
          </div>
          <Parallax
            translateX={["200px", "-100px"]}
            className={`${classes.flex_img} ${classes.margin_left}`}
          >
            <img src={webdev} alt="Web development illustration" />
          </Parallax>
        </div>
        <div className={`${classes.flex_item} ${classes.flex_item_row}`}>
          <Parallax
            translateX={["-200px", "100px"]}
            className={`${classes.flex_img} ${classes.margin_right}`}
          >
            <img src={appdev} alt="App development illustration" />
          </Parallax>
          <div
            className={`${classes.flex_description} ${classes.flex_item_column}`}
          >
            <h2>App development</h2>
            <p>
              Mobile applications are very popular these days. In fact, you
              should have a app if you want to scale your business. Don't worry
              I can built one for you. I use React Native, Redux Toolkit,
              Restful APIs, Mongodb and firebase to build such an app. The apps
              will be availble for both android and iOS.
            </p>
            <Link to="/contact">Hire me</Link>
          </div>
        </div>
        <div className={`${classes.flex_item} ${classes.flex_item_row}`}>
          <div
            className={`${classes.flex_description} ${classes.flex_item_column}`}
          >
            <h2>UI/UX design</h2>
            <p>
              Website or app is important but what more important is a great
              UI/UX design. Without having a good design, developing website is
              worth it. I design such User interface and User experience for
              businesses using Figma and Adobe Xd.
            </p>
            <Link to="/contact">Hire me</Link>
          </div>
          <Parallax
            translateX={["200px", "-100px"]}
            className={`${classes.flex_img} ${classes.margin_left}`}
          >
            <img src={uiux} alt="ui/ux design illustration" />
          </Parallax>
        </div>
        <div className={`${classes.flex_item} ${classes.flex_item_row}`}>
          <Parallax
            translateX={["-200px", "100px"]}
            className={`${classes.flex_img} ${classes.margin_right}`}
          >
            <img src={graphic} alt="Graphic design illustration" />
          </Parallax>
          <div
            className={`${classes.flex_description} ${classes.flex_item_column}`}
          >
            <h2>Graphic design</h2>
            <p>
              Your success depends on how well is your branding. Branding is
              very important to reach out your customer and influence the for
              the long term and for that you need a good logo to stand out from
              the crowd. I design business logo, visiting card, posters, flyers
              and social media post using Adobe Illustrator and Adobe Photoshop.
            </p>
            <Link to="/contact">Hire me</Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
