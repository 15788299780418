import classes from "./NotFound.module.css";

import notFound from "../assets/illustrations/404.png";

const NotFound = () => {
  return (
    <div className={classes.notfound_container}>
      <div className={classes.notfound}>
        <img src={notFound} alt="Oops! Page not found" />
      </div>
    </div>
  );
};

export default NotFound;
