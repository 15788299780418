import React, { useEffect, useRef, useState } from "react";
import classes from "./Contact.module.css";
import { db } from "../components/firebase-config";

import sky from "../assets/illustrations/sky.png";
import man from "../assets/illustrations/flying-man.png";
import clouds from "../assets/illustrations/clouds.png";
import sky2 from "../assets/sky2.png";
import cloud2 from "../assets/cloud2.png";
import { Parallax } from "react-scroll-parallax";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import validator from "validator";
import { setDoc, doc } from "firebase/firestore";
import emailjs from "@emailjs/browser";

const Contact = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const [nameWasTouched, setNameWasTouched] = useState(false);
  const [emailWasTouched, setEmailWasTouched] = useState(false);
  const [messageWasTouched, setMessageWasTouched] = useState(false);

  const [nameIsValid, setNameIsValid] = useState(false);
  const [emailIsValid, setEmailIsValid] = useState(false);
  const [messageIsValid, setMessageIsValid] = useState(false);
  const [formIsValid, setFormIsValid] = useState(false);

  const onNameChange = (event) => {
    setName(event.target.value);
  };
  const onEmailChange = (event) => {
    setEmail(event.target.value);
  };
  const onMessageChange = (event) => {
    setMessage(event.target.value);
  };

  const onNameBlur = () => {
    setNameWasTouched(true);
  };
  const onEmailBlur = () => {
    setEmailWasTouched(true);
  };
  const onMessageBlur = () => {
    setMessageWasTouched(true);
  };

  useEffect(() => {
    if (name.length > 0) {
      setNameIsValid(true);
    } else {
      setNameIsValid(false);
    }
    if (validator.isEmail(email)) {
      setEmailIsValid(true);
    } else {
      setEmailIsValid(false);
    }
    if (message.length > 9) {
      setMessageIsValid(true);
    } else {
      setMessageIsValid(false);
    }

    if (nameIsValid && emailIsValid && messageIsValid) {
      setFormIsValid(true);
    } else {
      setFormIsValid(false);
    }
  }, [
    name,
    email,
    message,
    nameIsValid,
    emailIsValid,
    messageIsValid,
    nameWasTouched,
    emailWasTouched,
    messageWasTouched,
  ]);

  const formRef = useRef();

  const onSubmitHandler = async (event) => {
    event.preventDefault();
    setNameWasTouched(true);
    setEmailWasTouched(true);
    setMessageWasTouched(true);

    if (formIsValid) {
  
      try {
        await setDoc(doc(db, "users", `${name}=${email}`), {
          name,
          email,
          message,
        });
        toast.success("Form submitted successfully!");
        emailjs
          .sendForm(
            "gmail",
            "template_tgfsp3o",
            formRef.current,
            "DKkcgQlwAidHb8wr4"
          )
          .then(
            (result) => {
              console.log(result.text);
            },
            (error) => {
              console.log(error.text);
            }
          );
        setTimeout(() => {
          toast.info(
            `hey ${name}, we got your query. we will contact you soon!`,
            {
              theme: "colored",
            }
          );
        }, 3000);
      } catch (error) {
        toast.error("A error occured. Please try again.", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          theme: "light",
        });
      }
      setName("");
      setEmail("");
      setMessage("");

      setNameWasTouched(false);
      setEmailWasTouched(false);
      setMessageWasTouched(false);
    } else {
      toast.warn("Please fill all the fileds!", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const enteredNameIsInvalid = !nameIsValid && nameWasTouched;
  const enteredEmailIsInvalid = !emailIsValid && emailWasTouched;
  const enteredMessageIsInvalid = !messageIsValid && messageWasTouched;

  return (
    <div className={classes.contact_container}>
      <div className={classes.primary_heading}>
        <Parallax translateX={["-200px", "50px"]}>
          <h1>
            <span className={classes.rubber}>C</span>
            <span className={classes.rubber}>o</span>
            <span className={classes.rubber}>n</span>
            <span className={classes.rubber}>t</span>
            <span className={classes.rubber}>a</span>
            <span className={classes.rubber}>c</span>
            <span className={classes.rubber}>t</span>
            <span className={classes.rubber_space}> </span>
            <span className={classes.rubber}>M</span>
            <span className={classes.rubber}>e</span>
          </h1>
        </Parallax>
        <div className={classes.underline}></div>
      </div>
      <div className={classes.form_container}>
        <div className={classes.illustration_mobile}>
          <div className={classes.illustration_img}>
            <img src={sky2} alt="sky" />
            <img src={man} alt="man" className={classes.man_hidden} />
            <img src={cloud2} alt="man" className={classes.cloud_hidden} />
          </div>
        </div>
        <div className={classes.form_field}>
          <h1>
            Let's build something spectacular togather, or just say hello. Come
            on, pull the trigger.
          </h1>
          <div className={classes.form}>
            <form ref={formRef}>
              <div className={classes.item}>
                <label htmlFor="name">
                  Your name{" "}
                  {enteredNameIsInvalid && (
                    <span className={classes.invalid}>
                      {" "}
                      Please Enter a valid name!
                    </span>
                  )}
                </label>
                <input
                  value={name}
                  onChange={onNameChange}
                  type="text"
                  id="name"
                  onBlur={onNameBlur}
                  required
                  name="name"
                />
              </div>
              <div className={classes.item}>
                <label htmlFor="email">
                  Your email
                  {enteredEmailIsInvalid && (
                    <span className={classes.invalid}>
                      {" "}
                      Please Enter a valid email!
                    </span>
                  )}
                </label>
                <input
                  value={email}
                  onChange={onEmailChange}
                  onBlur={onEmailBlur}
                  type="email"
                  id="email"
                  name="email"
                  required
                />
              </div>

              <div className={classes.item}>
                <label htmlFor="message">
                  Your thoughts
                  {enteredMessageIsInvalid && (
                    <span className={classes.invalid}>
                      {" "}
                      Message should contain atleast 10 characters!
                    </span>
                  )}
                </label>
                <textarea
                  value={message}
                  onChange={onMessageChange}
                  onBlur={onMessageBlur}
                  name="message"
                  id="message"
                ></textarea>
              </div>
              <div className={`${classes.item} ${classes.btn_cont}`}>
                <button type="submit" onClick={onSubmitHandler}>
                  Shoot <span>&#10230;</span>
                </button>
              </div>
            </form>
          </div>
        </div>
        <div className={classes.illustration}>
          <img src={sky} alt="sky" />
          <img src={man} alt="man" className={classes.man} />
          <img src={clouds} alt="man" className={classes.cloud} />
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Contact;
