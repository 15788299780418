import React from "react";
import classes from "./Skills.module.css";
import Typewriter from "typewriter-effect";
import mongodb from "../assets/mongodb.png";
import html from "../assets/html.png";
import css from "../assets/css.png";
import javascript from "../assets/javascript.png";
import sass from "../assets/sass.png";
import firebase from "../assets/firebase.png";
import react from "../assets/react.png";
import redux from "../assets/redux.png";
import figma from "../assets/figma.png";
import illustrator from "../assets/illustrator.png";
import photoshop from "../assets/photoshop.png";
import xd from "../assets/xd.png";
import { Parallax } from "react-scroll-parallax";

const Skills = () => {
  return (
    <div className={classes.skills_container}>
      <div className={classes.primary_heading}>
        <Parallax translateX={["-200px", "50px"]}>
          <h1>
            <span className={classes.rubber}>M</span>
            <span className={classes.rubber}>y</span>
            <span className={classes.rubber_space}> </span>
            <span className={classes.rubber}>S</span>
            <span className={classes.rubber}>k</span>
            <span className={classes.rubber}>i</span>
            <span className={classes.rubber}>l</span>
            <span className={classes.rubber}>l</span>
            <span className={classes.rubber}>s</span>
          </h1>
        </Parallax>
        <div className={classes.underline}></div>
      </div>
      <div className={classes.dynamic_text}>
        <h1>
          <Typewriter
            options={{
              strings: [
                "HTML",
                "CSS",
                "JavaScript",
                "SASS",
                "React JS",
                "React Native",
                "Redux Toolkit",
                "RESTful APIs",
                "Firebase",
                "MongoDB",
                "Figma",
                "Adobe Xd",
                "Adobe Illustrator",
                "Adobe Photoshop",
              ],
              autoStart: true,
              loop: true,
            }}
          />
        </h1>
      </div>
      <div className={classes.image_container}>
        <img
          src={html}
          id={classes.one}
          alt="html"
          className={classes.skills_icon}
        />
        <img
          src={css}
          id={classes.two}
          alt="css"
          className={classes.skills_icon}
        />
        <img
          src={javascript}
          alt="javascript"
          className={classes.skills_icon}
          id={classes.three}
        />
        <img
          src={sass}
          alt="sass"
          className={classes.skills_icon}
          id={classes.four}
        />
        <img
          src={react}
          id={classes.six}
          alt="react"
          className={classes.skills_icon}
        />
        <img
          src={redux}
          alt="redux"
          className={classes.skills_icon}
          id={classes.five}
        />
        <img
          src={firebase}
          alt="firebase"
          className={classes.skills_icon}
          id={classes.seven}
        />
        <img
          src={mongodb}
          alt="mongoDB"
          className={classes.skills_icon}
          id={classes.ten}
        />
        <img
          src={figma}
          alt="figma"
          className={classes.skills_icon}
          id={classes.nine}
        />
        <img
          src={illustrator}
          alt="illustrator"
          className={classes.skills_icon}
          id={classes.eight}
        />
        <img
          src={photoshop}
          id={classes.eleven}
          alt="photoshop"
          className={classes.skills_icon}
        />
        <img
          src={xd}
          id={classes.twelve}
          alt="xd"
          className={classes.skills_icon}
        />
      </div>
    </div>
  );
};

export default Skills;
