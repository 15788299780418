import React from "react";
import whatsapp from "../assets/icons/whatsapp.png";
import gmail from "../assets/icons/gmail.png";
import facebook from "../assets/icons/facebook.png";
import instagram from "../assets/icons/instagram.png";
import logo_white from "../assets/logo-white.png";
import classes from "./Footer.module.css";

const Footer = () => {
  return (
    <div className={classes.footer_container}>
      <div className={classes.footer_links}>
        <div className={classes.contact_links}>
          <h2>KNOCK ME</h2>
          <a href="https://wa.me/917908030878" target="blank">
            <div className={classes.icon_container}>
              <img src={whatsapp} alt="" />
              <span>Whats App</span>
            </div>
          </a>
          <a
            href="mailto:mindwebconsultancy@gmail.com?&subject=Regarding services"
            target="blank"
          >
            <div className={classes.icon_container}>
              <img src={gmail} alt="" />
              <span>Gmail</span>
            </div>
          </a>
        </div>
        <div className={classes.contact_links}>
          <h2>CONNECT ME WITH</h2>
          <a
            href="https://instagram.com/_mindweb_?igshid=YmMyMTA2M2Y="
            target="blank"
          >
            <div className={classes.icon_container}>
              <img src={instagram} alt="" />
              <span>Instagram</span>
            </div>
          </a>
          <a
            href="https://www.facebook.com/profile.php?id=100076693893867&mibextid=LQQJ4d"
            target="blank"
          >
            <div className={classes.icon_container}>
              <img src={facebook} alt="" />
              <span>Facebook</span>
            </div>
          </a>
        </div>
        <a href="/">
          <img src={logo_white} alt="" className={classes.footer_logo} />
        </a>
      </div>
      <hr />
      <div className={classes.footer_note}>
        <p>
          &#169; MindWeb Consultancy services | designed & build by Minhazur
          Rahaman
        </p>
      </div>
    </div>
  );
};

export default Footer;
