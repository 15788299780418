import React from "react";
import classes from "./About.module.css";
import Skills from "../UI/Skills";
import Works from "../UI/Works";
import { Parallax } from "react-scroll-parallax";

const About = () => {
  return (
    <>
      <div className={`${classes.about_container} ${classes.section_hidden}`}>
        <div className={classes.primary_heading}>
          <Parallax translateX={["-200px", "50px"]}>
            <h1>
              <span className={classes.rubber}>A</span>
              <span className={classes.rubber}>b</span>
              <span className={classes.rubber}>o</span>
              <span className={classes.rubber}>u</span>
              <span className={classes.rubber}>t</span>
              <span className={classes.rubber_space}> </span>
              <span className={classes.rubber}>m</span>
              <span className={classes.rubber}>e</span>
            </h1>
          </Parallax>
          <div className={classes.underline}></div>
        </div>
        <div className={classes.about_content}>
          <p className={classes.about_content__text}>
            I am a self-taught front-end web developer and also a graphic
            designer. For the record I studied Mechanical Engineering but my
            fascination about the web technology propelled me to become a web
            developer.
          </p>
          <br />
          <p className={classes.about_content__text}>
            Currently I am working as a freelance web developer. My creative
            mind also allows me to design products which are future-oriented and
            highly scaleable.
          </p>
          <br />
          <p className={classes.about_content__text}>
            I love to create, learn and explore diferent aspects of technology
            as well as life itself. In my free time I would like to draw some
            digital illustration but you can't say I am an artist (~_^).
          </p>
        </div>
      </div>
      <Works />
      <Skills />
    </>
  );
};

export default About;
