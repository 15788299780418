import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyC_wA35bK4mvYxVjiR9vDneQqwwWslVNZk",
  authDomain: "mindweb-b7387.firebaseapp.com",
  databaseURL: "https://mindweb-b7387-default-rtdb.firebaseio.com",
  projectId: "mindweb-b7387",
  storageBucket: "mindweb-b7387.appspot.com",
  messagingSenderId: "797159707921",
  appId: "1:797159707921:web:f871ff370a5945d91ce8b0",
  measurementId: "G-4P19E25LXL",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
