import React from "react";
import classes from "./Works.module.css";
import omnifood from "../assets/mockups/omnifood.png";
import { Parallax } from "react-scroll-parallax";
import dp from "../assets/mockups/docphar.png";
import et from "../assets/mockups/et.png";
import mw from "../assets/mockups/mw.png";
import vc from "../assets/mockups/vc.png";

const Works = () => {
  return (
    <div className={classes.works_container}>
      <div className={classes.primary_heading}>
        <Parallax translateX={["-200px", "50px"]}>
          <h1>
            <span className={classes.rubber}>M</span>
            <span className={classes.rubber}>y</span>
            <span className={classes.rubber_space}> </span>
            <span className={classes.rubber}>P</span>
            <span className={classes.rubber}>r</span>
            <span className={classes.rubber}>o</span>
            <span className={classes.rubber}>j</span>
            <span className={classes.rubber}>e</span>
            <span className={classes.rubber}>c</span>
            <span className={classes.rubber}>t</span>
            <span className={classes.rubber}>s</span>
          </h1>
        </Parallax>
        <div className={classes.underline}></div>
      </div>
      <div className={classes.grid_container}>
        <Parallax
          translateX={["200px", "-200px"]}
          className={classes.grid_item_image}
        >
          <img id={classes.img1} src={et} alt="expense tracker app" />
        </Parallax>
        <div className={classes.grid_item_content}>
          <h2>Expense Tracker</h2>
          <p>
            Expense Tracker a web app designed and developed by me. This fully
            responsive application is able to track your daily expenses and
            incomes. I have used react js, redux toolkit, pure css, firebase and
            restful APIs to built the website. Implementation of graphs gave an
            extra edge to the website.
          </p>
          <a
            href="https://expense-tracker-app-b5219.web.app/"
            className={classes.site_link}
            target="blank"
          >
            Visit site
          </a>
        </div>
        <div className={classes.grid_item_content}>
          <h2>Omnifood</h2>
          <p>
            One of my initial projects was Omnifood. It was built using HTML,
            CSS and vanila Javascript. The design was originally by the
            instructor but developed by me which include latest flexbox and grid
            system. Responsive design is also used in this website.
          </p>
          <a
            target="blank"
            href="https://omnifood-minhazur.netlify.app/"
            className={classes.site_link}
          >
            Visit site
          </a>
        </div>
        <Parallax
          translateX={["-200px", "200px"]}
          className={classes.grid_item_image}
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          <img src={omnifood} alt="omnifood project" />
        </Parallax>
        <Parallax
          translateX={["200px", "-200px"]}
          className={classes.grid_item_image}
        >
          <img src={mw} alt="mindweb project" />
        </Parallax>
        <div className={classes.grid_item_content}>
          <h2>MindWeb</h2>
          <p>
            My own portfolio website designed and built by me. I have used react
            js, pure CSS for styling, firebase for storing data to built the
            website. The design is fully responsive. The colour scheme, UI/UX
            and the logo is also designed by me using photoshop, figma and
            illustrator respectively.
          </p>
          <a target="blank" href="www.mwcs.in" className={classes.site_link}>
            Visit site
          </a>
        </div>
        <div className={classes.grid_item_content}>
          <h2>Sanan Photography</h2>
          <p>
            Sanan photography is a photographer who is also my client. I have
            designed the logo and visiting card for the client. Website for the
            same company is under process and will be developed soon.
          </p>
          {/* <a href="/" className={classes.site_link}>
            Visit site
          </a> */}
        </div>
        <Parallax
          translateX={["-200px", "200px"]}
          className={classes.grid_item_image}
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          <img src={vc} alt="visiting card design" />
        </Parallax>
        <Parallax
          translateX={["200px", "-200px"]}
          className={classes.grid_item_image}
        >
          <img id={classes.img2} src={dp} alt="doctarspharmacy logo" />
        </Parallax>
        <div className={classes.grid_item_content}>
          <h2>Doctar's Pharmacy</h2>
          <p>
            Doctar's pharmacy is local mediacal shop and diagonastic center who
            is my clinet. I have designed the logo and many other graphic
            content for the client.
          </p>
          <a
            target="blank"
            href="https://www.doctorspharmacy.shop/"
            className={classes.site_link}
          >
            Visit site
          </a>
        </div>
      </div>
    </div>
  );
};

export default Works;
