import React, { useState } from "react";
import { Link } from "react-router-dom";
import classes from "./Header.module.css";
import logo from "../assets/logo.svg";

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const onCloseHandler = () => {
    setIsMenuOpen(false);
  };
  const onOpenHandler = () => {
    setIsMenuOpen(true);
    console.log("menu opened");
  };

  return (
    <>
      <div className={classes.header__container}>
        <div className={classes.logo}>
          <Link to="/">
            <img src={logo} alt="logo" className={classes.logo_svg} />
          </Link>
        </div>
        <div>
          {isMenuOpen && (
            <button
              onClick={onCloseHandler}
              className={`${classes.btn_mobile_nav}`}
            >
              <ion-icon
                className={classes.icon_mobile_cross}
                name="close"
              ></ion-icon>
            </button>
          )}
          {!isMenuOpen && (
            <button
              onClick={onOpenHandler}
              className={`${classes.btn_mobile_nav} ${classes.btn_menu}`}
            >
              <ion-icon
                className={classes.icon_mobile_open}
                name="menu"
              ></ion-icon>
            </button>
          )}
        </div>
        <nav className={classes.nav__container}>
          <ul className={classes.nav__list}>
            <li className={classes.nav__list_item}>
              <Link to="/">
                Home<span className={classes.border_bottom}></span>
              </Link>
            </li>
            <li className={classes.nav__list_item}>
              <Link to="/about">
                About<span className={classes.border_bottom}></span>
              </Link>
            </li>
            <li className={classes.nav__list_item}>
              <Link to="/services">
                Services<span className={classes.border_bottom}></span>
              </Link>
            </li>
            <li className={classes.nav__list_item}>
              <Link to="/blogs">
                Blogs<span className={classes.border_bottom}></span>
              </Link>
            </li>
            <li className={classes.nav__list_item} id={classes.lastitem}>
              <Link to="/contact">Contact us</Link>
            </li>
          </ul>
        </nav>
      </div>
      <nav
        className={
          isMenuOpen
            ? `${classes.nav__container_mobile} ${classes.menu_open}`
            : `${classes.nav__container_mobile} ${classes.menu_close}`
        }
      >
        <ul className={classes.nav__list_mobile}>
          <li
            onClick={() => {
              setIsMenuOpen(false);
            }}
            className={classes.nav__list_item_mobile}
          >
            <Link to="/">
              Home<span className={classes.border_bottom_mobile}></span>
            </Link>
          </li>
          <li
            onClick={() => {
              setIsMenuOpen(false);
            }}
            className={classes.nav__list_item_mobile}
          >
            <Link to="/about">
              About<span className={classes.border_bottom_mobile}></span>
            </Link>
          </li>
          <li
            onClick={() => {
              setIsMenuOpen(false);
            }}
            className={classes.nav__list_item_mobile}
          >
            <Link to="/services">
              Services<span className={classes.border_bottom_mobile}></span>
            </Link>
          </li>
          <li
            onClick={() => {
              setIsMenuOpen(false);
            }}
            className={classes.nav__list_item_mobile}
          >
            <Link to="/blogs">
              Blogs<span className={classes.border_bottom_mobile}></span>
            </Link>
          </li>
          <li
            onClick={() => {
              setIsMenuOpen(false);
            }}
            className={classes.nav__list_item_mobile}
          >
            <Link to="/contact">Contact us</Link>
          </li>
        </ul>
      </nav>
    </>
  );
};

export default Header;
